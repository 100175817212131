/* eslint-disable lines-between-class-members */
import { M } from '@dashboard-experience/mastodon';
import { i18n, moment } from '@international/mastodon-i18n';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { RenderableSchemaProperty } from 'international/Fields/FieldCreationLogic/Field.types';
import Instructions from 'international/Components/Instructions';
import { clearFormValue } from '../../../../actions/intlForm';
import InternationalContext from '../../../InternationalContext';
import { UploadHeader } from '../../../Components';
import {
  dateInThePast,
  required,
  fieldMaxLengthFormat,
  emailFormat,
} from '../../../../lib/validations';
import generate from '../../FieldCreationLogic/FieldGenerators';
import { employerTitleGenerator } from '../../../../lib/employment/utils/employmentUtils';

const createDownloadValidator = (id: any) => {
  return (value: any, fields: any) => {
    const doNotContact = get(fields, id);
    if (doNotContact) {
      return required(value?.length);
    }

    return null;
  };
};

type DoNotContactDownloadInstructionsBodyProps = {
  fieldId: String;
  fieldGenerator: Function;
  validator: Function;
};
const DoNotContactDownloadInstructionsBody: React.FunctionComponent<DoNotContactDownloadInstructionsBodyProps> = (
  props: DoNotContactDownloadInstructionsBodyProps,
): React.ReactElement => {
  const { fieldId, fieldGenerator, validator } = props;

  return (
    <div>
      <div style={{ fontSize: 14 }}>
        {i18n.getStr('i18nInternational.employmentField.pleaseProvideProof')}
        <ul className='dashed'>
          <li>
            &nbsp;
            {i18n.getStr('i18nInternational.employmentField.governmentIssued')}
          </li>
          <li>
            &nbsp;
            {i18n.getStr('i18nInternational.employmentField.employerIssued')}
          </li>
        </ul>
      </div>
      <p>
        {i18n.getStr('i18nInternational.employmentField.documentsMustInclude')}
      </p>
      <div>
        {fieldGenerator(fieldId).render({
          validate: validator,
        })}
      </div>
    </div>
  );
};

type Props = {
  clearFormValue: any;
  id: string;
  properties: { [prop: string]: RenderableSchemaProperty };
  form?: any;
};

type State = {
  currentPosition: boolean;
};

class EmploymentField extends PureComponent<Props, State> {
  static id = 'employers';

  static readonly properties: { [key: string]: string } = Object.freeze({
    name: 'name',
    position: 'position',
    salary: 'salary',
    address: 'address',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country: 'country',
    phone_number: 'phone_number',
    manager_name: 'manager_name',
    manager_title: 'manager_title',
    manager_email: 'manager_email',
    manager_phone: 'manager_phone',
    do_not_contact: 'do_not_contact',
    start_date: 'start_date',
    end_date: 'end_date',
    url: 'url',
    documents: 'documents',
  });

  state = {
    currentPosition: false,
  };

  setCurrentPosition(currentPosition: boolean, fieldId: string) {
    const { clearFormValue: clearFormValueAction } = this.props;
    this.setState({ currentPosition });
    if (currentPosition) {
      clearFormValueAction(`${fieldId}.${EmploymentField.properties.end_date}`);
    }
  }

  renderDocumentUpload(
    fieldGenerator: Function,
    id: String,
  ): React.ReactElement {
    const { form } = this.props;
    const doNotContact = get(
      form,
      `${id}.${EmploymentField.properties.do_not_contact}`,
    );
    const validator = createDownloadValidator(
      `${id}.${EmploymentField.properties.do_not_contact}`,
    );

    if (doNotContact) {
      return (
        <Instructions
          title={i18n.getStr(
            'i18nInternational.employmentField.pleaseProvideInformation',
          )}
          text={
            <DoNotContactDownloadInstructionsBody
              fieldId={EmploymentField.properties.documents}
              validator={validator}
              fieldGenerator={fieldGenerator}
            />
          }
        />
      );
    }

    return (
      <>
        <UploadHeader key='upload-header' screening='employment' />
        {fieldGenerator(EmploymentField.properties.documents).render({
          validate: validator,
        })}
      </>
    );
  }

  render(): React.ReactElement {
    const { currentPosition } = this.state;
    const { form, id = EmploymentField.id, properties } = this.props;
    const index = id.substring(id.lastIndexOf('[') + 1, id.lastIndexOf(']'));
    const employers: any[] = get(form, EmploymentField.id);
    const hasCurrentPosition = employers.some(
      employer => employer && employer.current_position,
    );

    return (
      <InternationalContext.Consumer>
        {({ fieldGenerator, getFieldGeneratorForProperties }) => {
          const gen = getFieldGeneratorForProperties(properties, id);

          return (
            <div className='employment-item-border'>
              {M.Container.buildSubContents([
                <h1 key='title'>{employerTitleGenerator(index)}</h1>,
                [
                  gen(EmploymentField.properties.name).render(),
                  gen(EmploymentField.properties.country).render(),
                ],
                <div key='contact-section' className='employment-section-title'>
                  {i18n.getStr(
                    'i18nInternational.employmentField.companyContactInfo',
                  )}
                </div>,
                <p>
                  {i18n.getStr('i18nInternational.employmentField.contactGuidance')}
                </p>,
                [
                  gen(EmploymentField.properties.manager_name).render(),
                  gen(EmploymentField.properties.manager_title).render(),
                ],
                [
                  gen(EmploymentField.properties.manager_email).render(() => {
                    return {
                      validate: emailFormat,
                    };
                  }),
                  gen(EmploymentField.properties.manager_phone).render(() => {
                    return {
                      validate: val => fieldMaxLengthFormat(val, 30),
                    };
                  }),
                ],
                generate
                  .checkboxField({
                    id: `${id}.${EmploymentField.properties.do_not_contact}`,
                    title: (
                      <span style={{ fontSize: 14 }}>
                        {i18n.renderHTML(
                          'i18nInternational.employmentField.doNotContactThisEmployer',
                        )}
                      </span>
                    ),
                    placeholder: '',
                  })
                  .render(),
                this.renderDocumentUpload(gen, id),
                <div
                  key='employment-info-section'
                  className='employment-section-title'
                >
                  {i18n.getStr(
                    'i18nInternational.employmentField.employmentInfo',
                  )}
                </div>,
                [
                  gen(EmploymentField.properties.position).render(),
                  gen(EmploymentField.properties.salary).render(),
                ],
                [
                  gen(EmploymentField.properties.address).render(),
                  gen(EmploymentField.properties.city).render(),
                ],
                [
                  gen(EmploymentField.properties.state).render(),
                  gen(EmploymentField.properties.zipcode).render(),
                ],
                [
                  gen(EmploymentField.properties.phone_number).render(),
                  gen(EmploymentField.properties.url).render(),
                ],
                [
                  gen(EmploymentField.properties.start_date).render(
                    fieldId => ({
                      validate: (val, all, props) => {
                        const startDate = moment(
                          val,
                          i18n.getMonthYearPattern(),
                          true,
                        );
                        const endDate = moment(
                          get(
                            all,
                            `${id}.${EmploymentField.properties.end_date}`,
                          ),
                          i18n.getMonthYearPattern(),
                          true,
                        );
                        if (startDate.isValid() && endDate.isValid()) {
                          if (!startDate.isBefore(endDate)) {
                            return 'i18nInternational.employmentField.startDateMustPrecede';
                          }
                        }
                        return properties[fieldId]?.path?.isRequired
                          ? required(val)
                          : undefined;
                      },
                    }),
                  ),
                  <>
                    {gen(EmploymentField.properties.end_date).render(_ => {
                      const field = EmploymentField.properties.end_date;
                      const title = fieldGenerator.getTitle(
                        field,
                        properties[field],
                      );
                      return {
                        disabled: currentPosition,
                        labelText: title,
                        validate: val =>
                          val && dateInThePast(i18n.getMonthYearPattern())(val),
                      };
                    })}
                    {generate
                      .checkboxField({
                        id: `${id}.current_position`,
                        title: i18n.getStr(
                          'i18nInternational.employmentField.myCurrentPosition',
                        ),
                        placeholder: '',
                        disabled: !currentPosition && hasCurrentPosition,
                      })
                      .render(_ => ({
                        onClick: (e, val, prev) =>
                          this.setCurrentPosition(val, id),
                        valueOnInit: val => this.setCurrentPosition(!!val, id),
                        validate: (checked, all) => {
                          const endDateStr = get(
                            all,
                            `${id}.${EmploymentField.properties.end_date}`,
                          );
                          if (!endDateStr && !checked) {
                            return 'i18nInternational.employmentField.provideDateOrCheck';
                          }
                          return undefined;
                        },
                      }))}
                  </>,
                ],
              ])}
            </div>
          );
        }}
      </InternationalContext.Consumer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
});

export default connect(mapStateToProps, {
  clearFormValue,
})(EmploymentField);
