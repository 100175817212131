import React from 'react';
import styled from 'styled-components';
import { Translate as T } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import Panel from '../Panel';
import { Text } from '../fields';
import ControlFormImage from './assets/custody_control_form.png';
import '../../css/CustodyControlForm.scss';
import * as N from '../../lib/normalizations';
import * as V from '../../lib/validations';

const StyledCustodyPanel = styled(Panel)`
  .card-additional-text {
    font-weight: 700;
    margin-left: 2px;
  }

  .card-header .justify-content-md-end {
    justify-content: flex-start !important;
  }

  .mr-auto {
    margin-right: 0 !important;
    display: flex;
  }
`;

const CustodyControlForm = () => (
  <StyledCustodyPanel
    title='components.CustodyControlForm.title'
    additionalText='(Optional)'
  >
    <p>
      <T value='components.CustodyControlForm.body' dangerousHTML />
    </p>
    <img
      src={ControlFormImage}
      className='control-form-img'
      alt='Custody Control Form'
    />
    <Field
      type='text'
      name='custodyControlFormNumber'
      label='components.CustodyControlForm.label'
      placeholder='components.CustodyControlForm.placeholder'
      validate={[
        V.custodyControlNumberFormat,
        value => V.maxLengthFormat(value, 50),
      ]}
      normalize={N.smartPunctuation}
      component={Text}
    />
  </StyledCustodyPanel>
);

export default reduxForm({
  form: 'applyform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CustodyControlForm);
